<template>
    <v-layout row wrap class="longin-container">
      <v-flex md4 offset-md4>
        <v-card class="card-info">
          <v-card-title class="title-header">
            <span class="title">Connexion</span>
          </v-card-title>
          <v-card-text style="padding: 15px;">
            <form onsubmit="return false;">
              <v-text-field v-model="user" label="Identifiant"></v-text-field>
              <v-text-field v-model="pass" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" label="Mot de passe"  @click:append="show = !show" autocomplete="new-password"></v-text-field>
              <br>
              <v-btn type="submit" color="success" @click="tryConnect()" id="bt_log">Connexion</v-btn>
            </form>
            <div v-if="this.getLoginErrors().length != 0">
              <br>
              <v-alert type="warning" :value="this.getLoginErrors().length != 0" class="alert-danger">
              <div v-for="err in this.getLoginErrors()" :key="err">
                  {{ err }}
              </div>
              </v-alert>              
            </div>
          </v-card-text>
        </v-card>               
      </v-flex> 
    </v-layout>
</template>


<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex';
  
    export default {
      name: 'PanelLogin',
      methods : {
          ...mapGetters("global", [
            "getIsLog",
            "getToken",
            "getUser",
            "getPass",
            "getLoginErrors"
          ]),
          ...mapMutations("global", [
            "setIsLog",
            "setToken",
            "setUser",
            "setPass",
            "resetLoginErrors"
          ]),
          ...mapActions("global", [
            "getTokenConnect"
          ]),
          tryConnect : function() {
            this.resetLoginErrors()
            const _this = this
            this.getTokenConnect()
            .then(function () {
               _this.$router.push('/home');
            })
          }
        },
        data () {
          return {
            show: false
          }
        },
        computed : {
          pass: {
              get() {
                  return this.getPass();
              },
              set(val) {
                  this.setPass(val)
              }
          },
          user: {
              get() {
                  return this.getUser();
              },
              set(val) {
                  this.setUser(val)
              }
          }       
        }
    }
</script>


<style scoped lang="less">
  input {
    background-color: #888;
  }

  .b-form-group {
    font-weight: bold;

    .form-control {
      width:100%;
    }
  }

  .title-header {
    background-color: #272727;
    color:white;
  }

  #bt_log {
    margin-left:0px;
  }

  .card-info {
    margin-top: 50px;
  }

</style>
